<template>
    <div id="app">
        <router-view></router-view>
    </div>
</template>

<script>
/**
 * @future implementations and new features:
 * - Add withDataProcessingConsent to email and phone - Check controller for more information IMPORTANT.
 * - Add texts from DataPrivacyAndConsent if GDPR add-on is enabled for this tenant.
 */
export default {
    name: 'App'
};
</script>
