import Vue from 'vue';

import VueI18n from 'vue-i18n';
import messages from './i18n-messages';

import { SILENT_TRANSLATION_WARNINGS } from '../config/config';

Vue.use(VueI18n);

const i18n = new VueI18n({
    locale: 'en', // set locale
    fallbackLocale: 'en', // set fallback locale
    messages, // set locale messages
    silentTranslationWarn: SILENT_TRANSLATION_WARNINGS
});

export default i18n;
