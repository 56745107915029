import Vue from 'vue';
import Router from 'vue-router';
import NotFound from './views/NotFound.vue';

Vue.use(Router);

export default new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    scrollBehavior: () => ({ y: 0 }),
    routes: [
        {
            path: '/:tenantId',
            name: 'tenantRoot',
            redirect: to => {
                // @future : Add window replace?
                return to.path + '/subscribe/email';
            }
        },
        {
            path: '/:tenantId/one-click/:action(subscribe|unsubscribe)/email',
            name: 'one-click-subscribe-email',
            component: () => import(/* webpackChunkName: "oneclicksubscribeemail" */ './views/OneClickSubscribeEmail.vue')
        },
        {
            path: '/:tenantId/one-click/:action(subscribe|unsubscribe)/phone-number',
            name: 'one-click-subscribe-phone-number',
            component: () => import(/* webpackChunkName: "oneclicksubscribephonenumber" */ './views/OneClickSubscribePhoneNumber.vue')
        },
        {
            path: '/:tenantId/:action(subscribe|unsubscribe)/email',
            name: 'subscribe-email',
            component: () => import(/* webpackChunkName: "subscribeemail" */ './views/SubscribeEmail.vue')
        },
        {
            path: '/:tenantId/:action(subscribe|unsubscribe)/phone-number',
            name: 'subscribe-phone-number',
            component: () => import(/* webpackChunkName: "subscribephonenumber" */ './views/SubscribePhoneNumber.vue')
        },
        {
            path: '*',
            name: 'not-found',
            component: NotFound
        }
    ]
});
