const dictionary = {
    en: {
        attributes: {
            vv_firstName: 'First Name',
            vv_lastName: 'Last Name',
            vv_email: 'Email Address',
            vv_phoneNumber: 'Phone Number',
            vv_accountName: 'Account Name',
            vv_username: 'Username',
            vv_login: 'Username',
            vv_password: 'Password',
            vv_passwordRepeat: 'Password Repeat',
            vv_language: 'Language',
            vv_acceptTos: ''
        }
    },
    el: {
        attributes: {
            vv_firstName: 'Το Όνομα',
            vv_lastName: 'Το Επώνυμο',
            vv_email: 'Η Διεύθυνση Ηλ. Ταχυδρομείου',
            vv_phoneNumber: 'Ο Αριθμός Τηλεφώνου',
            vv_accountName: 'Το Όνομα Λογαριασμού',
            vv_username: 'Το Όνομα Χρήστη',
            vv_login: 'Το Όνομα Χρήστη',
            vv_password: 'Ο Κωδικός',
            vv_passwordRepeat: 'Ο κωδικός',
            vv_language: 'Η Γλώσσα',
            vv_acceptTos: 'Το πεδίο'
        }
    }
};

export default dictionary;
