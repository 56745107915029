<template>
    <div class="core-loader" v-bind:class="{ [paddingClass]: withPadding }">
        <div class="hollow-dots-spinner">
            <div class="dot"></div>
            <div class="dot"></div>
            <div class="dot"></div>
        </div>
    </div>
</template>

<script>
/**
 * Use it only for page loading.
 * CSS exists in public/css/pre-load.css
 */
export default {
    name: 'CoreLoader',
    props: {
        active: {
            type: Boolean,
            default: false,
            required: false
        },
        withPadding: {
            type: Boolean,
            default: false,
            required: false
        },
        paddingClass: {
            type: String,
            default: 'my-5',
            required: false
        }
    },
    data () {
        return {
            defaultColor: '#1070ca'
        };
    }
};
</script>

<style lang="scss" scoped>
    .core-loader {
        position: relative;
        width: 100%;
    }
    .hollow-dots-spinner {
        margin: 0 auto;
    }
</style>
