import Spinner from './Spinner';
import Ring from './Ring';
import MiniSpinner from './MiniSpinner';
import LineWave from './LineWave';
import LineScale from './LineScale';
import LineDown from './LineDown';
import BarFade from './BarFade';
import BarFadeScale from './BarFadeScale';

export default {
    Spinner,
    Ring,
    MiniSpinner,
    LineWave,
    LineScale,
    LineDown,
    BarFade,
    BarFadeScale
};
