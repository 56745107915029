import Vue from 'vue';

import Logo from '../../components/Logo';
import CoreLoader from '../../components/patterns/CoreLoader';
import ElementLoading from '../../components/loading/ElementLoading';

const AppComponents = {
    init () {
        // App Global Registration //////////
        Vue.component('logo', Logo);
        Vue.component('core-loader', CoreLoader);
        Vue.component('element-loading', ElementLoading);
    }
};

export default AppComponents;
