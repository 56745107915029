import Vue from 'vue';
import axios from 'axios';
import VueAxios from 'vue-axios';
import AxiosErrorTransformer from 'vuetiful/src/transformers/axios-error.transformer';

const AppApiService = {
    init () {
        Vue.use(VueAxios, axios);
        Vue.axios.defaults.withCredentials = true;

        // Intercept failed responses and transform error response.
        Vue.axios.interceptors.response.use(null, (error) => {
            // Transform error.
            error = AxiosErrorTransformer.flatten(error);
            error = AxiosErrorTransformer.setMessages(error);

            // eslint-disable-next-line
            return Promise.reject(error);
        });
    }
};

export default AppApiService;
