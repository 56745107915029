<template>
    <div class="not-found-view ViewWrapper">
        <div class="container">
            <div class="row">
                <div class="col-sm-9 col-md-7 col-lg-5 mx-auto">

                    <!-- LOGO -->
                    <div class="text-center my-5">
                        <logo :width="'164px'" :color="'#46b881'"></logo>
                    </div>

                    <!-- BODY -->
                    <div class="gk-card no-border mb-5">
                        <!-- TITLE -->
                        <div class="mb-4">
                            <p class="mb-0 text-center weight-3 size-4">{{ $t('Cloutlayer Subscriptions Portal') }}</p>
                        </div>
                        <p style="text-align: justify">{{ $t('Front_page_why_you_are_here') }}</p>
                        <p style="text-align: justify">{{ $t('Front_page_how_did_you_get_here') }}</p>
                        <!--
                        @future : Implement tenant search.
                        <p style="text-align: justify">{{ $t('Front_page_how_to_deal_with_it') }}</p>
                        <form class="form-inline mb-3">
                            <div class="form-group mr-2 mb-0">
                                <label for="domainName" class="sr-only">{{ $t('Domain Name') }}</label>
                                <input type="text" class="form-control" id="domainName" placeholder="Domain Name">
                            </div>
                            <button type="button" class="btn btn-primary mb-0">{{ $t('Search') }}</button>
                        </form>
                        -->
                        <p class="text-muted" style="text-align: justify">{{ $t('Front_page_get_help_from_us') }}</p>
                    </div>

                    <common-box-footer></common-box-footer>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import CommonBoxFooter from './Snippets/CommonBoxFooter';

export default {
    name: 'NotFoundView',
    components: {
        CommonBoxFooter
    }
};
</script>
