import Vue from 'vue';

const AppVueConfig = {
    init () {
        Vue.config.productionTip = false;
        Vue.config.devtools = true;
    }
};

export default AppVueConfig;
