import Vue from 'vue';
import i18n from '../plugins/i18n';

const LocaleMixin = {
    methods: {
        // i18n //////////
        getAppLocale () {
            return i18n.locale;
        },
        changeAppLocale (locale) {
            if (!locale) {
                locale = 'en';
            }
            i18n.locale = locale;
        },
        changeAppLocaleToEl () {
            i18n.locale = 'el';
        },
        changeAppLocaleToEn () {
            i18n.locale = 'en';
        },
        // Deprecated. Until then is very useful for template use
        // especially where v-if condition is required
        // or we don't know if key points to an existed translation
        // (regardless if trans value is null - if key exists in locale translations).
        hasProbValidTranslation (key) {
            if (typeof key !== 'string') return false;
            const value = i18n.t(key);
            if (typeof value !== 'string') return false;
            if (value.trim() === '') return false;
            return key !== value;
        }
    }
};

/**
 * AppMixins (Global Mixin).
 */
const AppMixins = {
    init () {
        Vue.mixin({
            methods: {
                ...LocaleMixin.methods
            }
        });
    }
};

export default AppMixins;
