import Vue from 'vue';

import VeeValidate, { Validator } from 'vee-validate';
import validationMessagesEn from 'vee-validate/dist/locale/en';
import validationMessagesEl from 'vee-validate/dist/locale/el';
import i18n from '../plugins/i18n';
import dictionary from '../plugins/i18n-vee-validate';
import { parsePhoneNumberFromString } from 'libphonenumber-js';

const AppDependencies = {
    init () {
        // Vee Validate
        Vue.use(VeeValidate, {
            i18nRootKey: 'validations', // customize the root path for validation messages.
            i18n,
            dictionary: {
                en: validationMessagesEn,
                el: validationMessagesEl
            }
        });
        VeeValidate.Validator.extend('password_strength', {
            getMessage: field => 'The password must contain at least: 1 uppercase letter, 1 lowercase letter, 1 number, and one special character (8 - 32 chars)',
            validate: value => {
                var strongRegex = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})');
                return strongRegex.test(value);
            }
        });
        VeeValidate.Validator.extend('int_phone_validation', {
            getMessage: field => '_ERROR_MESSAGE_',
            validate: value => {
                const phoneNumber = parsePhoneNumberFromString('+' + value);
                if (phoneNumber) {
                    if (phoneNumber.isValid()) {
                        return true;
                    } else {
                        return false;
                    }
                } else {
                    return false;
                }
            }
        });
        Validator.localize(dictionary);
    }
};

export default AppDependencies;
