<template>
    <div class="common-box-footer">
        <!-- FOOTER LINKS -->
        <div class="text-center mb-4">
            <span class="text-primary small cursor-pointer mr-2" @click="changeAppLocale('el')">Ελληνικά</span>
            <span class="text-primary small cursor-pointer" @click="changeAppLocale('en')">English</span>
            <hr class="my-2"/>
            <p class="small text-muted mb-1">©2019 {{ $t('Cloutlayer.') }} {{ $t('All Rights Reserved.') }}</p>
            <a href="#" class="small mr-2">{{ $t('Privacy Policy') }}</a>
            <a href="#" class="small mr-4">{{ $t('Terms of Use') }}</a>
        </div>
    </div>
</template>

<script>
export default {
    name: 'CommonBoxFooter'
};
</script>
