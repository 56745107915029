const messages = {
    'en': {
        'Cloutlayer': 'Cloutlayer',
        'Cloutlayer.': 'Cloutlayer.',
        'All Rights Reserved': 'All Rights Reserved',
        'All Rights Reserved.': 'All Rights Reserved.',
        'Privacy Policy': 'Privacy Policy',
        'Terms of Use': 'Terms of Use',
        'Terms Of Use': 'Terms Of Use',
        // NotFound.vue
        'Cloutlayer Subscriptions Portal': 'Cloutlayer Subscriptions Portal',
        'Front_page_why_you_are_here': 'You probably found this page because one of our customers used Cloutlayer to send you an email campaign and you traced a link in the email back here to investigate.',
        'Front_page_how_did_you_get_here': 'If you found this page by clicking a link inside an email then probably something went wrong. Please contact the sender of this email to provide you further information about how you can update your communication preferences.',
        'Front_page_how_to_deal_with_it': 'Αν γνωρίζεις το domain name του αποστολέα του μηνύματος που έλαβες, πληκτρολόγησέ το στη φόρμα που εμφανίζεται παρακάτω και πάτα \'Αναζήτηση\'. Αν το domain name είναι σωστό, θα μεταφερθείς στη φόρμα ενημέρωσης των προτιμήσεων επικοινωνίας με το συγκεκριμένο αποστολέα.',
        'Front_page_get_help_from_us': 'In any case, we are here to help. Contact us via the contact form on our website.',
        'Domain Name': 'Domain Name',
        'Search': 'Search',
        // SubscribeEmail.vue
        'Email Communication Subscription': 'Email Communication Subscription',
        'Email': 'Email',
        'Email Address': 'Email Address',
        'Email address': 'Email address',
        'Enter Email': 'Enter Email',
        'Enter email': 'Enter email',
        'Subscribe': 'Subscribe',
        'Unsubscribe': 'Unsubscribe',
        'Check to select this type of communication subscription': 'Check to select this type of communication subscription',
        'Type_Personal_Email': 'Personal Email',
        'Type_Marketing_Email': 'Marketing Email',

        'Phone Number Communication Subscription': 'Phone Number Communication Subscription',
        'Type_Personal_Sms': 'Person Text Messages (SMS)',
        'Type_Marketing_Sms': 'Marketing Text Messages (SMS)',
        'Type_Personal_Call': 'Personal Calls',
        'Type_Marketing_Call': 'Marketing Calls',
        'Phone Number': 'Phone Number',
        'Phone number': 'Phone number',
        'Enter phone number': 'Enter phone number',

        'Well Done': 'Well Done',
        'Well done': 'Well done',
        'Your subscription has been submitted': 'Your subscription has been submitted',
        'Your subscription has been canceled': 'Your subscription has been canceled',

        'Phone_Number_Validation_1': 'Phone Number must be a valid phone number in E164 format. UK example +447911123456'
    },
    'el': {
        'Cloutlayer': 'Cloutlayer',
        'Cloutlayer.': 'Cloutlayer.',
        'All Rights Reserved.': 'Με Επιφύλαξη Παντός Δικαιώματος.',
        'Privacy Policy': 'Πολιτική Απορρήτου',
        'Terms of Use': 'Όροι Χρήσης',
        'Terms Of Use': 'Όροι Χρήσης',
        // NotFound.vue
        'Cloutlayer Subscriptions Portal': 'Πύλη Διαχείρισης Συνδρομών Επικοινωνίας - Cloutlayer',
        'Front_page_why_you_are_here': 'Πιθανόν να βρεθήκατε σε αυτή τη σελίδα επειδή κάποιος από τους πελάτες μας χρησιμοποίησε το Cloutlayer για να σας στείλει ένα μήνυμα ηλεκτρονικού ταχυδρομείου (email).',
        'Front_page_how_did_you_get_here': 'Αν βρεθήκατε στη συγκεκριμένη σελίδα κάνοντας κλικ σε κάποιο σύνδεσμο εγγραφής ή απεγγραφής τότε μάλλον κάτι έχει πάει στραβά. Παρακαλούμε όπως επικοινωνήσετε με τον αποστολέα του μηνύματος για να σας δώσει οδηγίες για το πώς μπορείτε να ενημερώσετε τις προτιμήσεις επικοινωνίας σας.',
        'Front_page_how_to_deal_with_it': 'Αν γνωρίζει το domain name του αποστολέα του μηνύματος που έλαβες, πληκτρολόγησέ το στη φόρμα που εμφανίζεται παρακάτω και πάτα \'Αναζήτηση\'. Αν το domain name είναι σωστό, θα μεταφερθείς στη φόρμα ενημέρωσης των προτιμήσεων επικοινωνίας με το συγκεκριμένο αποστολέα.',
        'Front_page_get_help_from_us': 'Σε κάθε περίπτωση είμαστε εδώ για να σας βοηθήσουμε. Επικοινωνήστε μαζί μας μέσω της φόρμας επικοινωνίας που βρίσκεται στην ιστοσελίδας μας.',
        'Domain Name': 'Domain Name',
        'Search': 'Αναζήτηση',
        // SubscribeEmail.vue
        'Email Communication Subscription': 'Συνδρομή Επικοινωνίας μέσω Email',
        'Email': 'Email',
        'Email Address': 'Διεύθυνση Ηλ. Ταχυδρομείου (Email)',
        'Email address': 'Διεύθυνση Ηλ. Ταχυδρομείου (Email)',
        'Enter Email': 'Πληκτρολογήστε το Email σας',
        'Enter email': 'Πληκτρολογήστε το email σας',
        'Subscribe': 'Εγγραφή',
        'Unsubscribe': 'Απεγγραφή',
        'Check to select this type of communication subscription': 'Κάντε κλικ στο πλαίσιο για να επιλέξετε το συγκεκριμένο τύπο επικοινωνίας',
        'Type_Personal_Email': 'Προσωπικά Μηνύματα Email',
        'Type_Marketing_Email': 'Προωθητικά Μηνύματα Email',

        'Phone Number Communication Subscription': 'Συνδρομή Επικοινωνίας μέσω Τηλεφώνου',
        'Type_Personal_Sms': 'Προσωπικά Μήνύματα SMS',
        'Type_Marketing_Sms': 'Προωθητικά Μηνύματα SMS',
        'Type_Personal_Call': 'Προσωπικές Κλήσεις',
        'Type_Marketing_Call': 'Προωθητικές Κλήσεις',
        'Phone Number': 'Αριθμός Τηλεφώνου',
        'Phone number': 'Αριθμός τηλεφώνου',
        'Enter phone number': 'Πληκτρολογήστε τον αριθμό τηλεφώνου σας',

        'Well Done': 'Πολύ Καλά',
        'Well done': 'Πολύ καλά',
        'Your subscription has been submitted': 'Η συνδρομή σας καταχωρήθηκε',
        'Your subscription has been canceled': 'Η συνδρομή σας καταργήθηκε',

        'Phone_Number_Validation_1': 'Ο Αριθμός Τηλεφώνου πρέπει να είναι έγκυρος και να έχει τη μορφή Ε164. Π.χ. Για Ελλάδα +306971234567 ή +302101234567'
    }
};

export default messages;
